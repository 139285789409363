<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2> {{ data.setCompanyData.company_name }} </h2>
                            </div>
                            <div>
                                <button class="btn btn-primary" type="button"
                                   @click="editFormData({url: `${data.setURL}/${$route.params.id}`, modalId: 'showModal'})">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"/>
                                        </svg>
                                    </span>
                                    <span>{{ translations?.update }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <div class="row">
                            <div class="col-2">
                                <div class="company-pic">
                                    <img :src="data.setCompanyData.logo" alt="" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-10">
                                <div class="company-data">
                                    <p class="mb-3"><span class="fw-bold">{{ translations?.contact_person }}: </span>
                                        <span>{{ data.setCompanyData.name }}</span>
                                    </p>
                                    <p class="mb-3"><span class="fw-bold">{{ translations?.contact_email }}: </span>
                                        <span>{{ data.setCompanyData.email }}</span>
                                    </p>
                                    <p class="mb-3"><span class="fw-bold">{{ translations?.contact_mobile }}: </span>
                                        <span>{{ data.setCompanyData.mobile }}</span>
                                    </p>
                                    <p class="mb-3"><span class="fw-bold">{{ translations?.address }}: </span>
                                        <span>{{ data.setCompanyData.address }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="editCompanyModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ translations?.company_update }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                    <update-company-form
                        :form-data="data"
                        @form-action="formAction"
                        @form-file="formFile"
                        :image-url="data.imageUrl"
                    >
                    </update-company-form>
            </div>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
    import {useRoute} from "vue-router";
    import HelperFunction  from "@/common/helpers";
    import { onMounted, computed } from 'vue'
    import {useStore} from "vuex";
    import UpdateCompanyForm from "@/views/auth/administrator/company/UpdateCompanyForm";

    export default {
        name: "CompanyDetails",
        components: {UpdateCompanyForm},
        setup() {
            const { editFormData, data, storeFormData, getFormFile, permissionResolver } = HelperFunction();
            const route = useRoute()
            data.setURL = vueConfig.Company.CompanyEndPoint
            data.setCompanyData = {}

            onMounted(() => {
                getCompanyData();
            });

            const store = useStore();

            const translations = computed(() => {
                return store.state.translations;  
            });

            /** watch **/
            permissionResolver('/administrator/companies', false);
            /** watch end **/

            async function getCompanyData() {
                await editFormData({url: `${data.setURL}/${route.params.id}`})
                .then(() => {
                    data.setCompanyData = data.inputFormData
                })
            }
            async function formAction(object, data) {
                data._method = 'PUT'
                object.url = `${object.url}/${data.id}`
                await storeFormData(object, data)
                .then(() => {
                    getCompanyData()
                })
            }
            function formFile(event, name) {
                getFormFile(event, name)
            }

            return {
                data, formAction, editFormData, formFile, translations
            }
        }
    }
</script>

<style scoped>

</style>